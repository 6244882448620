<template>
  <div>
    <div v-if="loadingNote">
      <OtherLoading />
    </div>
    <div v-else>
      <b-modal
        ref="modalEditNote"
        centered
        @show="show"
        @hidden="hide"
        body-class="p-4"
        v-model="isShowModal"
      >
        <template #modal-header>
          <div class="ft-black">Additional Notes</div>
        </template>
        <div>
          <InputTextArea
            :rows="4"
            textFloat="Additional Notes (if any)"
            placeholder="Additional Notes"
            id="note"
            v-model="form.note"
            :isValidate="v.form.note.$error"
            :v="v.form.note"
          />
        </div>
        <template #modal-footer>
          <b-row>
            <b-col
              ><b-button @click="hide" class="btn-cancel">
                Cancel
              </b-button></b-col
            >
            <b-col
              ><b-button class="btn-confirm" @click.prevent="saveNote">
                Save
              </b-button></b-col
            >
          </b-row>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputTextArea from "@/components/inputs/InputTextArea";
export default {
  components: {
    InputTextArea,
    OtherLoading,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    loadingNote: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isShowModal: false,
    };
  },
  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    },
    saveNote() {
      this.v.form.$touch();
      if (this.v.form.$error) {
        return;
      }
      this.$emit("updateNote", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--secondary-color);
}
.ft-black {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
::v-deep .modal-footer {
  display: block;
}
</style>
